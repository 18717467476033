@import "abstracts/variables";
@import "abstracts/functions";
@import "abstracts/mixins";
@import "base/typography";
@import "pages/header";

section {
  +section {
    +section {
      background-color: #fff;
      height: 100vh;
      color: #fff;
      background-color: #fff;
      color: #fff;
      text-align: center;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}